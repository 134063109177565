import { createBrowserRouter } from "react-router-dom";
import Loadable from "../Constant/Loadable";
import * as React from "react";
const Contact = Loadable(React.lazy(() => import("../Page/Contact/Contact")));
const AboutUs = Loadable(React.lazy(() => import("../Page/AboutUs/AboutUs")));
const Doctors = Loadable(
  React.lazy(() => import("../Page/DoctorsPage/Doctors"))
);
const Specialties = Loadable(
  React.lazy(() => import("../Page/Specialties/Specialties"))
);
const Services = Loadable(
  React.lazy(() => import("../Page/Services/Services"))
);
const NewsSingle = Loadable(
  React.lazy(() => import("../Page/News/NewsSingle"))
);
const SingleSpecialties = Loadable(
  React.lazy(() => import("../Page/Specialties/SingleSpecialties"))
);
const SingleServices = Loadable(
  React.lazy(() => import("../Page/Services/SingleServices"))
);
const NotFoundPage = Loadable(
  React.lazy(() => import("../Page/Notfound/NotFoundPage"))
);
const News = Loadable(React.lazy(() => import("../Page/News/News")));
const Tarifs = Loadable(React.lazy(() => import("../Page/Tarifs/Tarifs")));
const Home = Loadable(React.lazy(() => import("../Page/Home/Home")));

export const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "/contact",
    element: <Contact />,
  },
  {
    path: "/À-propos-de-nous",
    element: <AboutUs />,
  },
  {
    path: "/médecins",
    element: <Doctors />,
  },
  {
    path: "/actualités",
    element: <News />,
  },
  {
    path: "/tarifs",
    element: <Tarifs />,
  },
  {
    path: "/actualités/:newspost_id",
    element: <NewsSingle />,
  },
  {
    path: "/spécialités",
    element: <Specialties />,
  },
  {
    path: "/spécialités/:specialtie_id",
    element: <SingleSpecialties />,
  },
  {
    path: "/Services/:Services_id",
    element: <SingleServices />,
  },
  {
    path: "/Services",
    element: <Services />,
  },
  {
    path: "/*",
    element: <NotFoundPage />,
  },
]);
