import "./Constant/Colors.css";
import { RouterProvider } from "react-router-dom";
import { router } from "./Routes/Routes";
import { QueryClientProvider, QueryClient } from "react-query";
import { animateScroll } from "react-scroll";
import { FloatButton } from "antd";
import { ArrowUpOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
const queryClient = new QueryClient();

const options = {
  // your options here, for example:
  duration: 100,
  smooth: true,
};

function App() {
  const [scrollPosition, setScrollPosition] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      setScrollPosition(window.scrollY);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <div className="App">
      <QueryClientProvider client={queryClient}>
        <RouterProvider router={router} />
      </QueryClientProvider>

      {scrollPosition > 1000 && (
        <FloatButton
          icon={<ArrowUpOutlined />}
          onClick={() => animateScroll.scrollToTop(options)}
          type="default"
        />
      )}
    </div>
  );
}

export default App;
