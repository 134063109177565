/* eslint-disable react/display-name */
import { Suspense } from "react";
import React from "react";

// project imports
import Loader from "./Loader";

// ==============================|| LOADABLE - LAZY LOADING ||============================== //

const Loadable = (Component) => (props) =>
(
	<Suspense fallback={<Loader />} style={{ height: '100vh !important' }}>
		<Component {...props} />
	</Suspense>
);

export default Loadable;
